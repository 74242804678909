import React, { FC, useMemo } from 'react';
import Headphones from './icons/Headphones';
import Search from './icons/Search';
import Invite from './icons/Invite';
import Profile from './icons/Profile';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useUser } from '../lib/hooks/user';

const navBarSectionClass = 'flex flex-col items-center justify-center w-10';
const navBarMobileActiveClass = 'dark:text-dragonfruit-1 text-plum-1';
const sharedSvgClass = 'h-7 w-7';

const NavBarMobile: FC = () => {
  const router = useRouter();
  const { data: user } = useUser();
  const activePath = useMemo(() => router.asPath, [router]);

  return (
    <>
      {user ? (
        <nav className="flex w-full grow items-center justify-around bg-gray-8 p-2 text-xs font-bold dark:bg-black-1 dark:text-gray-7">
          <Link
            href="/listen"
            className={`${navBarSectionClass} ${activePath === '/listen' && navBarMobileActiveClass}`}
          >
            <Headphones
              strokeWidth="1.75"
              svgClass={sharedSvgClass}
              pathClass={`${
                activePath === '/listen'
                  ? 'dark:stroke-dragonfruit-1 stroke-plum-1'
                  : 'dark:stroke-gray-7 stroke-black-1 '
              }`}
            />
            <p>Listen</p>
          </Link>
          <Link
            href="/discover"
            className={`${navBarSectionClass} ${activePath === '/discover' && navBarMobileActiveClass}`}
          >
            <Search
              svgClass={sharedSvgClass}
              pathClass={`${
                activePath === '/discover' ? 'dark:fill-dragonfruit-1 fill-plum-1' : 'dark:fill-gray-7 fill-black-1 '
              }`}
            />
            <p>Discover</p>
          </Link>
          <Link
            href="/me/invite"
            className={`${navBarSectionClass} ${activePath === '/me/invite' && navBarMobileActiveClass}`}
          >
            <Invite
              strokeWidth="1.75"
              svgClass={sharedSvgClass}
              pathClass={`${
                activePath === '/me/invite'
                  ? 'dark:stroke-dragonfruit-1 stroke-plum-1'
                  : 'dark:stroke-gray-7 stroke-black-1 '
              }`}
            />
            <p>Invite</p>
          </Link>
          <Link
            href="/me"
            className={`self-end ${navBarSectionClass} ${activePath === '/me' && navBarMobileActiveClass}`}
          >
            <Profile
              strokeWidth="1.75"
              svgClass={sharedSvgClass}
              pathClass={`${
                activePath === '/me' ? 'dark:stroke-dragonfruit-1 stroke-plum-1' : 'dark:stroke-gray-7 stroke-black-1 '
              }`}
            />
            <p>Me</p>
          </Link>
        </nav>
      ) : null}
    </>
  );
};
export default NavBarMobile;
