import type { AggregationStreamInclude } from '../../types';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import calendar from 'dayjs/plugin/calendar';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { STREAM_DAILY_DURATION_MULTIPLIER } from '../../constants';
dayjs.extend(calendar);
dayjs.extend(updateLocale);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.updateLocale('en', {
  calendar: {
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    lastWeek: '[Last] dddd',
    nextWeek: 'dddd',
    sameElse: 'MMM D, YYYY',
  },
});

export { dayjs };

export const FIVE_MIN_IN_SEC = 5 * 60;

type ParseDateTime = {
  date: string | Date;
  dateStyle?: 'full' | 'long' | 'medium' | 'short' | undefined;
  timeStyle?: 'full' | 'long' | 'medium' | 'short' | undefined;
};

export const parseDateToLocal = ({ date, dateStyle, timeStyle }: ParseDateTime): string => {
  const dateObj = new Date(date);
  return new Intl.DateTimeFormat('en-US', { dateStyle, timeStyle }).format(dateObj);
};

export const fractionMinute = (durationSecs: number) => {
  if (durationSecs < 60) {
    return `${Math.round(durationSecs / 10) * 10} secs`;
  }
  if (durationSecs < 75) {
    return '1 min';
  }
  if (durationSecs < 105) {
    return '1½ mins';
  }
  if (durationSecs < 135) {
    return '2 mins';
  }
  if (durationSecs < 165) {
    return '2½ mins';
  }
  if (durationSecs < 195) {
    return '3 mins';
  }
  if (durationSecs < 225) {
    return '3½ mins';
  }
  return `${(durationSecs / 60).toFixed()} mins`;
};

export const hourAMPM = (hour: number): string => {
  if (hour >= 0 && hour < 0.5) {
    return 'midnight';
  }
  if (hour < 11.5) {
    return `${hour.toFixed(0)} AM`;
  }
  if (hour >= 11.5 && hour < 12.5) {
    return '12 PM';
  }
  if (hour >= 12.5 && hour < 23.5) {
    return `${parseInt(hour.toFixed(0), 10) - 12} PM`;
  }
  if (hour >= 23.5 && hour < 24) {
    return 'midnight';
  }
  if (hour >= 24) {
    return hourAMPM(hour % 24);
  }
  return '';
};

export const getTotalDurationUserAggregationStreams = (userAggregationStreamsInclude: AggregationStreamInclude[]) => {
  let totalDuration = 0;
  userAggregationStreamsInclude.forEach((x: AggregationStreamInclude) => {
    totalDuration +=
      x?.stream?.avg_duration && !isNaN(parseInt(x?.stream?.avg_duration, 10))
        ? parseInt(x?.stream?.avg_duration, 10) *
          (x?.stream?.frequency && STREAM_DAILY_DURATION_MULTIPLIER[x?.stream?.frequency]
            ? STREAM_DAILY_DURATION_MULTIPLIER[x?.stream?.frequency]
            : 1)
        : 0;
  });
  return fractionMinute(totalDuration);
};

export const fromNow = (dateTime: string) => {
  return dayjs(dateTime).fromNow();
};

export const sleep = (ms: number) => {
  // add ms millisecond timeout before promise resolution
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const calendarDate = (date: string) => {
  const calendarFormat = {
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    sameWeek: 'dddd',
    lastWeek: '[Last] dddd',
    sameElse: 'MMM D, YYYY',
  };

  return dayjs(date).calendar(undefined, calendarFormat);
};

export const roundToFiveMinutesAvg = (n: number) => `${Math.round(n / 60 / 5) * 5} mins`;
export const fractionHour = (durationSecs: number) => {
  const durationMin = durationSecs / 60;
  if (durationMin < 60) {
    return roundToFiveMinutesAvg(durationSecs);
  }
  if (durationMin < 75) {
    return '1 hour';
  }
  if (durationMin < 105) {
    return '1½ hours';
  }
  if (durationMin < 135) {
    return '2 hours';
  }
  if (durationMin < 165) {
    return '2½ hours';
  }
  if (durationMin < 195) {
    return '3 hours';
  }
  if (durationMin < 225) {
    return '3½ hours';
  }
  return `${(durationMin / 60).toFixed()} hours`;
};

/**
 * Format seconds to HH:mm or mm:ss
 *
 * @param totalSeconds
 * @returns {string}
 */
export const formatSecondsToHhmmssOrMmss = (totalSeconds: number) => {
  const isNegative = totalSeconds < 0;
  let roundedTotalSeconds = Math.round(Math.abs(totalSeconds));

  const hours = Math.floor(roundedTotalSeconds / 3600);
  roundedTotalSeconds %= 3600;
  const minutes = Math.floor(roundedTotalSeconds / 60);
  const seconds = roundedTotalSeconds % 60;

  const minutesString = hours > 0 ? String(minutes).padStart(2, '0') : String(minutes);
  const secondsString = String(seconds).padStart(2, '0');

  let timeString;

  if (totalSeconds < 600) {
    // For less than 10 minutes return time in mm:ss format
    timeString = `${minutesString}:${secondsString} min`;
  } else if (totalSeconds < 3600) {
    // For less than an hour return time in mm format
    timeString = `${minutesString} min`;
  } else {
    // For greater than an hour return time in HH:mm format
    timeString = `${hours > 0 ? `${hours}:` : ''}${minutesString} hr`;
  }

  timeString = isNegative ? `-${timeString}` : timeString;

  return timeString;
};
