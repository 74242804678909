import classNames from 'classnames';
import Head from 'next/head';
import { LegalInfoOption } from '../../types';
import Footer from '../Footer';
import MiniPlayer from '../player/MiniPlayer';
import NavBarDesktop from '../NavBarDesktop';
import NavBarMobile from '../NavBarMobile';
import { ToastContainer } from '../../lib/toast';
import StickyFooter from '../screens/core/StickyFooter';

type ResponsivePageLayoutProps = {
  title: string;
  children: React.ReactNode;
  showLegalFooter?: LegalInfoOption;
  hideNavLinks?: boolean;
  verticalAlignment?: 'top' | 'center';
  mainMargin?: 'none' | 'default';
};

export default function ResponsivePageLayout({
  title,
  children,
  showLegalFooter = LegalInfoOption.WhenLoggedOut,
  hideNavLinks,
  verticalAlignment = 'top',
  mainMargin = 'default',
}: ResponsivePageLayoutProps) {
  return (
    <>
      <Head>
        <title>{`${title} | Jam`}</title>
        <meta name="theme-color" content="#ffffff" media="(prefers-color-scheme: light)" />
        <meta name="theme-color" content="#000000" media="(prefers-color-scheme: dark)" />
        <link rel="apple-touch-icon" href="apple-touch-icon.png" />
        <style>{`#__next { height: 100% }`}</style>
      </Head>
      <div className="flex min-h-full flex-col justify-between dark:bg-black-0">
        <NavBarDesktop hideAllLinks={hideNavLinks} />

        <ToastContainer />
        <main
          className={classNames(
            'mx-auto flex w-full grow flex-col sm:w-[600px]',
            verticalAlignment === 'top' ? 'pb-20 pt-4' : 'my-auto items-center justify-center',
            { 'px-6 sm:px-0': mainMargin === 'default' },
          )}
        >
          {children}
        </main>
        <Footer showLegalInfo={showLegalFooter} />
        <StickyFooter>
          <MiniPlayer />
          <div className="flex sm:hidden">
            <NavBarMobile />
          </div>
        </StickyFooter>
      </div>
    </>
  );
}
