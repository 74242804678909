import { SvgFC } from '../../types';

const SkipForward: SvgFC = ({ svgClass, pathClass }) => (
  <svg role="img" viewBox="0 0 24 24" fill="none" className={svgClass} xmlns="http://www.w3.org/2000/svg">
    <title>Skip forward</title>
    <path
      d="M17.7028 11.36L6.39109 4.44732C6.2774 4.37784 6.14725 4.3399 6.01403 4.33741C5.88082 4.33492 5.74934 4.36796 5.63313 4.43313C5.51692 4.49831 5.42017 4.59327 5.35283 4.70824C5.28549 4.82321 5.25 4.95404 5.25 5.08728V18.9127C5.25 19.0459 5.28549 19.1768 5.35283 19.2917C5.42017 19.4067 5.51692 19.5017 5.63313 19.5668C5.74934 19.632 5.88082 19.6651 6.01403 19.6626C6.14725 19.6601 6.2774 19.6221 6.39109 19.5527L17.7028 12.6399C17.8124 12.573 17.903 12.4789 17.9658 12.3669C18.0287 12.2548 18.0617 12.1285 18.0617 12C18.0617 11.8715 18.0287 11.7452 17.9658 11.6331C17.903 11.5211 17.8124 11.427 17.7028 11.36V11.36Z"
      stroke="#121212"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={pathClass}
    />
    <path
      d="M18.75 3.75V20.25"
      stroke="#121212"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={pathClass}
    />
  </svg>
);

export default SkipForward;
