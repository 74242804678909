import Link from 'next/link';
import { FC, useMemo } from 'react';
import useHasMounted from '../lib/hooks/useHasMounted';
import { useUser } from '../lib/hooks/user';
import { LegalInfoOption } from '../types';

type FooterProps = {
  showLegalInfo: LegalInfoOption;
};

const Footer: FC<FooterProps> = ({ showLegalInfo }) => {
  const { data: user, isValidating: isUserValidating } = useUser();
  const hasMounted = useHasMounted();

  const isGuestUser = !isUserValidating && !user;

  const isVisible = useMemo<boolean>(() => {
    switch (showLegalInfo) {
      case LegalInfoOption.Always:
        return true;
      case LegalInfoOption.Never:
        return false;
      case LegalInfoOption.WhenLoggedOut:
        return isGuestUser;
    }
  }, [showLegalInfo, isGuestUser]);

  if (!hasMounted || !isVisible) return null;

  return (
    <footer className="flex flex-col gap-2 pb-10 pt-7 text-center text-xs text-gray-3" data-testid="legal-info">
      <div className="flex justify-center gap-2">
        <Link href="/privacy" data-testid="privacy-link" className="hover:underline">
          Privacy
        </Link>
        <Link href="/terms" data-testid="terms-link" className="hover:underline">
          Terms
        </Link>
        <Link href="/community-guidelines" data-testid="community-guidelines-link" className="hover:underline">
          Community Guidelines
        </Link>
      </div>
      <p className="text-xs text-gray-3">©{' ' + new Date().getFullYear()} Alto Industries Inc.</p>
    </footer>
  );
};

export default Footer;
