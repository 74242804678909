import Image from 'next/image';
import type { Stream } from '../../types';
import classNames from 'classnames';
import sizes from '../../lib/image/sizes';
import { s3LocationToPath } from '../../lib/util/s3';

type MobileSize = 'sm' | 'md' | 'miniplayer' | 'lg' | 'xl';
type DesktopSize = 'sm' | 'md' | 'miniplayer' | 'lg' | 'xl';

type ImageMeta = {
  classNames: string;
  width: number;
};

const MOBILE_META_MAP: Record<MobileSize, ImageMeta> = {
  sm: {
    classNames: 'w-10 h-10 rounded-lg',
    width: 40,
  },
  md: {
    classNames: 'w-14 h-14 rounded-lg',
    width: 56,
  },
  miniplayer: {
    classNames: 'w-16 h-16',
    width: 64,
  },
  lg: {
    classNames: 'w-18 h-18 rounded-xl',
    width: 72,
  },
  xl: {
    classNames: 'w-20 h-20 rounded-xl',
    width: 80,
  },
};

const DESKTOP_META_MAP: Record<MobileSize, ImageMeta> = {
  sm: {
    classNames: 'sm:w-10 sm:h-10 sm:rounded-lg',
    width: 40,
  },
  md: {
    classNames: 'sm:w-14 sm:h-14 sm:rounded-lg',
    width: 56,
  },
  miniplayer: {
    classNames: 'sm:w-16 sm:h-16 rounded-l-xl',
    width: 64,
  },
  lg: {
    classNames: 'sm:w-18 sm:h-18 sm:rounded-xl',
    width: 72,
  },
  xl: {
    classNames: 'sm:w-20 sm:h-20 sm:rounded-xl',
    width: 80,
  },
};

const CoverImage = ({
  mobileSize,
  desktopSize,
  providedImageUrl,
  stream,
}: {
  stream: Pick<Stream, 'image_url' | 'name' | 'image_bucket' | 'image_key'>;
  mobileSize: MobileSize;
  desktopSize: DesktopSize;
  providedImageUrl?: string | null | undefined;
}) => {
  const classes = classNames(
    `relative overflow-hidden border-0.5 border-black-0/10 dark:border-white-0/10 `,
    MOBILE_META_MAP[mobileSize].classNames,
    DESKTOP_META_MAP[desktopSize].classNames,
  );

  const imageUrl = providedImageUrl
    ? providedImageUrl
    : stream.image_key && stream.image_bucket
    ? s3LocationToPath({ key: stream.image_key, bucket: stream.image_bucket })
    : stream.image_url;

  return (
    <div className={classes}>
      {imageUrl && (
        <Image
          src={imageUrl}
          alt={`${stream?.name} cover image`}
          fill={true}
          sizes={sizes({
            desktopWidth: DESKTOP_META_MAP[desktopSize].width,
            mobileWidth: MOBILE_META_MAP[mobileSize].width,
          })}
        />
      )}
    </div>
  );
};

export default CoverImage;
