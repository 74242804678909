import { useEffect } from 'react';
import { PlaylistEventHandler, PlaylistEventType } from '../../types';
import PlaylistPlayer from '../audio/PlaylistPlayer';

export const usePlaylistEvents = (eventTypes: PlaylistEventType[], handler: PlaylistEventHandler) => {
  useEffect(() => {
    PlaylistPlayer.addEventListeners(eventTypes, handler);
    return () => {
      PlaylistPlayer.removeEventListeners(eventTypes, handler);
    };
  }, [eventTypes, handler]);
};
