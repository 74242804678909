import { SvgFC } from '../../types';

const SkipForward10: SvgFC = ({ svgClass, pathClass }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" className={svgClass}>
    <title>Skip forward 10 seconds</title>
    <path
      d="M11.5 14.5L14.5 11.5V18.5"
      stroke="#1E1E1E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={pathClass}
    />
    <path
      d="M17.5 13.5C17.5 12.3954 18.3954 11.5 19.5 11.5V11.5C20.6046 11.5 21.5 12.3954 21.5 13.5V16.5C21.5 17.6046 20.6046 18.5 19.5 18.5V18.5C18.3954 18.5 17.5 17.6046 17.5 16.5V13.5Z"
      stroke="#1E1E1E"
      strokeWidth="2"
      strokeLinejoin="round"
      className={pathClass}
    />
    <path
      d="M12.2297 22C6.85615 22 2.5 17.7467 2.5 12.5C2.5 7.25329 6.85615 3 12.2297 3C15.7214 3 18.7835 4.79582 20.5 7.4931"
      stroke="#1E1E1E"
      strokeWidth="2"
      strokeLinecap="round"
      className={pathClass}
    />
    <path
      d="M21 2V8H15"
      stroke="#1E1E1E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={pathClass}
    />
  </svg>
);

export default SkipForward10;
