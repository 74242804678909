export type S3Location = {
  bucket: string;
  key: string;
};

const regex = /s3:\/\/(?<bucket>[\w-]*)\/(?<key>.*)/;

export function s3LocationToPath(s3: S3Location): string {
  return `s3://${s3.bucket}/${s3.key}`;
}

export function pathToS3Location(path: string): S3Location {
  const capturedGroups = path.match(regex)?.groups;
  if (!capturedGroups || !capturedGroups.key || !capturedGroups.bucket) {
    throw new Error(`${path} is not an s3 path`);
  }
  return {
    key: capturedGroups.key,
    bucket: capturedGroups.bucket,
  };
}

export function isS3Path(path: string): boolean {
  return path?.startsWith('s3://');
}
