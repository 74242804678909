import { FC, useEffect, useState } from 'react';
import { sleep } from './util/dateTime';
import { DEFAULT_AUTO_CLOSE_DELAY } from '../constants';
type Toast = {
  id: number;
  toastElement: React.ReactNode;
  type?: 'success' | 'error' | 'info';
  autoCloseDelay?: number;
  createdAt?: Date;
};

export const toastService = {
  create: (params: Omit<Toast, 'id'>) => {},
  close: (id: number) => {},
};

let idCount = 0;
export const ToastContainer: FC = () => {
  const [toasts, setToasts] = useState<Record<string, Toast | undefined>>({});

  const removeToast = (id: number) => {
    setToasts((prev) => {
      if (prev[id]) {
        return { ...prev, [id]: undefined };
      }
      return prev;
    });
  };

  const addToast = async ({
    toastElement,
    type = 'success',
    autoCloseDelay = DEFAULT_AUTO_CLOSE_DELAY,
  }: Omit<Toast, 'id'>) => {
    const id = idCount;
    idCount++;

    setToasts((prev) => ({
      ...prev,
      [id]: {
        id,
        createdAt: new Date(),
        type,
        toastElement,
        autoCloseDelay,
      },
    }));

    await sleep(autoCloseDelay);
    removeToast(id);
  };
  useEffect(() => {
    if (toastService) {
      toastService['create'] = addToast;
      toastService['close'] = removeToast;
    }
  });

  const ToastStack = () => (
    <>
      {Object.values(toasts)
        .filter((toast) => toast)
        .sort((a, b) => {
          if (a?.createdAt && b?.createdAt) {
            return a.createdAt > b.createdAt ? 1 : -1;
          }
          return -1;
        })
        .map((toast) => {
          if (toast) {
            const { id, toastElement, type } = toast;
            return (
              <div
                role={type}
                className="mb-2"
                onClick={() => removeToast(id)}
                onKeyPress={() => removeToast(id)}
                key={id}
              >
                {toastElement}
              </div>
            );
          }
          return null;
        })}
    </>
  );

  return (
    <div className="flex justify-center">
      <div className="fixed z-50 mt-3 w-full px-8 sm:w-[600px] sm:px-0">
        <ToastStack />
      </div>
    </div>
  );
};
