import { SvgFC } from '../../types';

const Play: SvgFC = ({ svgClass, pathClass }) => (
  <svg role="img" viewBox="0 0 24 24" fill="none" className={svgClass} xmlns="http://www.w3.org/2000/svg">
    <title>Play</title>
    <path
      d="M19.453 11.36 8.14 4.447A.75.75 0 0 0 7 5.087v13.826a.75.75 0 0 0 1.141.64l11.312-6.913a.75.75 0 0 0 0-1.28v0Z"
      className={pathClass}
      stroke="#121212"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Play;
