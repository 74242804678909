import { SvgFC } from '../../types';

const Profile: SvgFC = ({ strokeWidth, svgClass, pathClass }) => {
  return (
    <svg className={svgClass} viewBox="0 0 24 24" fill="none">
      <circle cx="12" cy="7" r="4" className={pathClass} strokeWidth={strokeWidth ? strokeWidth : '2'} fill="none" />
      <path
        d="M12.0005 14.25C7.92239 14.25 3.78333 16.5 3.01739 20.7469C2.92364 21.2587 3.21473 21.75 3.75098 21.75H20.2505C20.7868 21.75 21.0764 21.2587 20.9841 20.7469C20.2177 16.5 16.0786 14.25 12.0005 14.25Z"
        className={pathClass}
        strokeWidth={strokeWidth ? strokeWidth : '2'}
        strokeMiterlimit="10"
        fill="none"
      />
    </svg>
  );
};

export default Profile;
