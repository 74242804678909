import { SvgFC } from '../../types';

const Speed2x: SvgFC = ({ svgClass, pathClass }) => (
  <svg role="img" viewBox="0 0 24 24" fill="none" className={svgClass} xmlns="http://www.w3.org/2000/svg">
    <title>2x playback speed</title>
    <path
      d="M15.9999 10.1096L20.0908 15.0186"
      stroke="#121212"
      strokeWidth="2"
      strokeLinecap="round"
      className={pathClass}
    />
    <path
      d="M20.0909 10.1096L16 15.0186"
      stroke="#121212"
      strokeWidth="2"
      strokeLinecap="round"
      className={pathClass}
    />
    <path
      d="M5.62046 7.99892C5.79817 7.57847 6.06185 7.19983 6.39458 6.88734C6.7273 6.57484 7.1217 6.33539 7.55246 6.18436C7.98322 6.03333 8.4408 5.97406 8.89582 6.01037C9.35084 6.04668 9.79323 6.17775 10.1946 6.39517C10.5959 6.61259 10.9474 6.91156 11.2263 7.27286C11.5053 7.63416 11.7056 8.04982 11.8144 8.49312C11.9232 8.93643 11.9381 9.3976 11.858 9.84699C11.778 10.2964 11.6049 10.7241 11.3498 11.1026L5.36328 18L11.9087 17.9992"
      stroke="#121212"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={pathClass}
    />
  </svg>
);

export default Speed2x;
