import { SvgFC } from '../../types';

const Speed1_2x: SvgFC = ({ svgClass, pathClass }) => (
  <svg role="img" viewBox="0 0 24 24" fill="none" className={svgClass} xmlns="http://www.w3.org/2000/svg">
    <title>1.2x playback speed</title>
    <path
      d="M9.62046 10.9989C9.79817 10.5785 10.0619 10.1998 10.3946 9.88734C10.7273 9.57484 11.1217 9.33539 11.5525 9.18436C11.9832 9.03333 12.4408 8.97406 12.8958 9.01037C13.3508 9.04668 13.7932 9.17775 14.1946 9.39517C14.5959 9.61259 14.9474 9.91156 15.2263 10.2729C15.5053 10.6342 15.7056 11.0498 15.8144 11.4931C15.9232 11.9364 15.9381 12.3976 15.858 12.847C15.778 13.2964 15.6049 13.7241 15.3498 14.1026L9.36328 21L15.9087 20.9992"
      stroke="#121212"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={pathClass}
    />
    <path
      d="M1 7.27272L3.90908 6L3.90908 18"
      stroke="#121212"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={pathClass}
    />
    <path
      d="M18.9091 13.1096L23 18.0186"
      stroke="#121212"
      strokeWidth="2"
      strokeLinecap="round"
      className={pathClass}
    />
    <path
      d="M23.0001 13.1096L18.9092 18.0186"
      stroke="#121212"
      strokeWidth="2"
      strokeLinecap="round"
      className={pathClass}
    />
    <circle cx="7.2998" cy="18" r="1" fill="#121212" className={pathClass} />
  </svg>
);

export default Speed1_2x;
