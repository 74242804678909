import { SvgFC } from '../../types';

const Headphones: SvgFC = ({ strokeWidth, svgClass, pathClass }) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" className={svgClass}>
      <path
        className={pathClass}
        d="M18.2194 15.8182C17.4493 18.2431 15.1797 20 12.4999 20C9.81998 20 7.55045 18.2431 6.78029 15.8182C6.78033 15 6.78024 13 6.78029 12.1818C7.55045 9.75694 9.81998 8 12.4999 8C15.1797 8 17.4493 9.75694 18.2194 12.1818C18.2194 13 18.2194 15 18.2194 15.8182Z"
        stroke={pathClass}
        strokeWidth={strokeWidth ? strokeWidth : '2'}
        strokeLinejoin="round"
      />
      <path
        className={pathClass}
        d="M10.5 16C10.5 16 11 16.6667 12.5 16.6667C14 16.6667 14.5 16 14.5 16"
        stroke={pathClass}
        strokeWidth={strokeWidth ? strokeWidth : '2'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClass}
        d="M22.2521 11.7778C21.2416 7.32447 17.259 4 12.4999 4C7.74085 4 3.75824 7.32447 2.74773 11.7778H3.74994V14V16.2222C3.74994 16.2222 2.59994 15.0674 2.59994 13.5C2.59994 12.7362 2.58556 12.4925 2.74773 11.7778C3.75824 7.32447 7.74085 4 12.4999 4C17.259 4 21.2416 7.32447 22.2521 11.7778C22.4143 12.4925 22.3999 12.7362 22.3999 13.5C22.3999 15.0674 21.2499 16.2222 21.2499 16.2222V14V11.7778L22.2521 11.7778Z"
        fill="#1E1E1E"
      />
      <path
        className={pathClass}
        d="M22.2521 11.7778C21.2416 7.32447 17.259 4 12.4999 4C7.74085 4 3.75824 7.32447 2.74773 11.7778M22.2521 11.7778C22.4143 12.4925 22.3999 12.7362 22.3999 13.5C22.3999 15.0674 21.2499 16.2222 21.2499 16.2222C21.2499 16.2222 21.2499 15.5 21.2499 14C21.2499 12.5 21.2499 11.7778 21.2499 11.7778L22.2521 11.7778ZM2.74773 11.7778C2.58556 12.4925 2.59994 12.7362 2.59994 13.5C2.59994 15.0674 3.74994 16.2222 3.74994 16.2222C3.74994 16.2222 3.74994 15.5 3.74994 14C3.74994 12.5 3.74994 11.7778 3.74994 11.7778H2.74773Z"
        stroke={pathClass}
        strokeWidth={strokeWidth ? strokeWidth : '2'}
        strokeLinejoin="round"
      />
      <circle cx="10.5" cy="12.5" r="0.5" stroke={pathClass} />
      <circle cx="14.5" cy="12.5" r="0.5" stroke={pathClass} />
    </svg>
  );
};

export default Headphones;
