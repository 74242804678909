import { SvgFC } from '../../types';

const SearchIcon: SvgFC = ({ svgClass, pathClass }) => (
  <svg role="img" viewBox="0 0 24 24" className={svgClass} fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Search</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      className={pathClass}
      d="M11.5 2.5C6.80558 2.5 3 6.30558 3 11C3 15.6944 6.80558 19.5 11.5 19.5C13.4869 19.5 15.3145 18.8183 16.7618 17.676L20.4524 21.3666C20.8429 21.7571 21.4761 21.7571 21.8666 21.3666C22.2571 20.9761 22.2571 20.3429 21.8666 19.9524L18.176 16.2618C19.3183 14.8145 20 12.9869 20 11C20 6.30558 16.1944 2.5 11.5 2.5ZM5 11C5 7.41015 7.91015 4.5 11.5 4.5C15.0899 4.5 18 7.41015 18 11C18 14.5899 15.0899 17.5 11.5 17.5C7.91015 17.5 5 14.5899 5 11Z"
      fill="none"
    />
  </svg>
);
export default SearchIcon;
