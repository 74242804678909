import classNames from 'classnames';
import { FC } from 'react';

type StickyFooterProps = {
  children: React.ReactNode;
  className?: string;
};

const StickyFooter: FC<StickyFooterProps> = ({ className, children }) => {
  return <div className={classNames(className, 'fixed bottom-0 z-20 w-full safe-bottom')}>{children}</div>;
};
export default StickyFooter;
