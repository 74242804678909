import { SvgFC } from '../../types';
const InviteIcon: SvgFC = ({ strokeWidth, svgClass, pathClass }) => (
  <svg role="img" viewBox="0 0 24 24" className={svgClass} fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Invite</title>
    <path
      d="M12 15C14.0711 15 15.75 13.3211 15.75 11.25C15.75 9.17893 14.0711 7.5 12 7.5C9.92893 7.5 8.25 9.17893 8.25 11.25C8.25 13.3211 9.92893 15 12 15Z"
      stroke="#121212"
      strokeWidth={strokeWidth ? strokeWidth : '2'}
      strokeMiterlimit="10"
      className={pathClass}
    />
    <path
      d="M5.98145 18.6913C6.54639 17.5806 7.40768 16.6478 8.46997 15.9963C9.53226 15.3448 10.7541 15 12.0003 15C13.2464 15 14.4683 15.3448 15.5306 15.9963C16.5929 16.6478 17.4542 17.5806 18.0191 18.6913"
      stroke="#121212"
      strokeWidth={strokeWidth ? strokeWidth : '2'}
      strokeLinecap="round"
      strokeLinejoin="round"
      className={pathClass}
    />
    <path
      d="M16.5 5.25H21"
      stroke="#121212"
      strokeWidth={strokeWidth ? strokeWidth : '2'}
      strokeLinecap="round"
      strokeLinejoin="round"
      className={pathClass}
    />
    <path
      d="M18.75 3V7.5"
      stroke="#121212"
      strokeWidth={strokeWidth ? strokeWidth : '2'}
      strokeLinecap="round"
      strokeLinejoin="round"
      className={pathClass}
    />
    <path
      d="M20.889 10.5804C21.187 12.4559 20.8834 14.3776 20.0218 16.07C19.1602 17.7623 17.7849 19.1384 16.093 20.0009C14.4012 20.8634 12.4797 21.1681 10.604 20.8713C8.72828 20.5744 6.99475 19.6912 5.65193 18.3484C4.30911 17.0056 3.42596 15.2721 3.1291 13.3964C2.83224 11.5207 3.13691 9.59916 3.99944 7.9073C4.86196 6.21544 6.23805 4.84013 7.93039 3.97856C9.62274 3.11699 11.5444 2.8134 13.42 3.11132"
      stroke="#121212"
      strokeWidth={strokeWidth ? strokeWidth : '2'}
      strokeLinecap="round"
      strokeLinejoin="round"
      className={pathClass}
    />
  </svg>
);
export default InviteIcon;
