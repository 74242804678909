import { SvgFC } from '../../types';

const Speed1_5x: SvgFC = ({ svgClass, pathClass }) => (
  <svg role="img" viewBox="0 0 25 24" fill="none" className={svgClass} xmlns="http://www.w3.org/2000/svg">
    <title>1.5x playback speed</title>
    <path
      d="M16.0904 9L10.6133 9L9.54492 14.4821C10.0789 13.9482 10.7592 13.5845 11.4998 13.4372C12.2405 13.2899 13.0082 13.3655 13.7058 13.6545C14.4035 13.9434 14.9998 14.4328 15.4194 15.0607C15.8389 15.6886 16.0628 16.4268 16.0628 17.1819C16.0628 17.9371 15.8389 18.6752 15.4194 19.3031C14.9998 19.931 14.4035 20.4204 13.7058 20.7094C13.0082 20.9983 12.2405 21.074 11.4998 20.9266C10.7592 20.7793 10.0789 20.4157 9.54492 19.8817"
      stroke="#121212"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={pathClass}
    />
    <path
      d="M1 7.27272L3.90908 6L3.90908 18"
      stroke="#121212"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={pathClass}
    />
    <circle cx="7" cy="18" r="1" fill="#121212" className={pathClass} />
    <path
      d="M18.9999 13.1096L23.0908 18.0186"
      stroke="#121212"
      strokeWidth="2"
      strokeLinecap="round"
      className={pathClass}
    />
    <path
      d="M23.0909 13.1096L19 18.0186"
      stroke="#121212"
      strokeWidth="2"
      strokeLinecap="round"
      className={pathClass}
    />
  </svg>
);

export default Speed1_5x;
