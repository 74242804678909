import { isS3Path, pathToS3Location } from '../util/s3';

const LEGACY_IMAGE_CDN = process.env.NEXT_PUBLIC_LEGACY_IMAGE_CDN as string;
const IMAGE_CDN = process.env.NEXT_PUBLIC_IMAGE_CDN;

function cloudflareLoader({
  src,
  width,
  height,
  fit = 'cover',
}: {
  src: string;
  width: number;
  height: number;
  fit?: string;
}): string {
  const params = [`width=${width}`, `height=${height}`, `fit=${fit}`];
  const paramsString = params.join(',');
  return `https://api.jam.ai/cdn-cgi/image/${paramsString}/${src}`;
}

function getImageCdnUrl({
  bucket,
  key,
  width,
  height,
}: {
  bucket: string;
  key: string;
  width: number;
  height: number;
}) {
  const imageRequest = {
    bucket,
    key,
    edits: {
      resize: {
        width: width,
        height: height,
      },
      sharpen: {
        // sharpen_amount - Sharpen amount. Typical values are between 0.0 and 10.0
        // sharpen_radius - Sharpen radius. Typical values are between 0.0 and 2.0
        // luminance_only - Sharpen only luminance channel. Values can be true or false.
        // sigma = 1 + radius / 2
        // confirmed by looking at logs from the other format, which sends: edits: { resize: { width: 144, height: 144, fit: 'inside' }, sharpen: 1.01 },
        sigma: 1.01,
      },
      normalize: true,
    },
  };
  return `${IMAGE_CDN}/${btoa(JSON.stringify(imageRequest))}`;
}

function getLegacyImageCdnUrl(url: string, width: number, height: number) {
  if (url.startsWith(LEGACY_IMAGE_CDN)) {
    return `${LEGACY_IMAGE_CDN}/fit-in/${width}x${height}/filters:sharpen(0.05,0.02,true)${url.replace(
      LEGACY_IMAGE_CDN,
      '',
    )}`;
  } else if (url.startsWith('http://')) {
    return cloudflareLoader({ src: url, width, height });
  } else {
    return url;
  }
}

export default function loader({ src, width /* quality */ }: { src: string; width: number }) {
  if (isS3Path(src)) {
    const { bucket, key } = pathToS3Location(src);
    return getImageCdnUrl({ bucket, key, width, height: width });
  } else {
    return getLegacyImageCdnUrl(src, width, width);
  }
}
