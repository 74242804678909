import { SvgFC } from '../../types';

const SkipBackward10: SvgFC = ({ svgClass, pathClass }) => (
  <svg role="img" viewBox="0 0 24 24" fill="none" className={svgClass} xmlns="http://www.w3.org/2000/svg">
    <title>Skip backward 10 seconds</title>
    <path
      d="M2.5 14.5L5.5 11.5V18.5"
      stroke="#1E1E1E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={pathClass}
    />
    <path
      d="M12.2703 22C17.6439 22 22 17.7467 22 12.5C22 7.25329 17.6439 3 12.2703 3C8.77861 3 5.71653 4.79582 4 7.4931"
      stroke="#1E1E1E"
      strokeWidth="2"
      strokeLinecap="round"
      className={pathClass}
    />
    <path
      d="M3.5 2V8H9.5"
      stroke="#1E1E1E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={pathClass}
    />
    <path
      d="M8.5 13.5C8.5 12.3954 9.39543 11.5 10.5 11.5V11.5C11.6046 11.5 12.5 12.3954 12.5 13.5V16.5C12.5 17.6046 11.6046 18.5 10.5 18.5V18.5C9.39543 18.5 8.5 17.6046 8.5 16.5V13.5Z"
      stroke="#1E1E1E"
      strokeWidth="2"
      strokeLinejoin="round"
      className={pathClass}
    />
  </svg>
);

export default SkipBackward10;
