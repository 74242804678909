import { SvgFC } from '../../types';

const Speed1x: SvgFC = ({ svgClass, pathClass }) => (
  <svg role="img" viewBox="0 0 24 24" fill="none" className={svgClass} xmlns="http://www.w3.org/2000/svg">
    <title>1x playback speed</title>
    <path
      d="M5 7.27272L7.90908 6V18"
      stroke="#121212"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={pathClass}
    />
    <path
      d="M13.9091 10.1096L18 15.0186"
      stroke="#121212"
      strokeWidth="2"
      strokeLinecap="round"
      className={pathClass}
    />
    <path
      d="M18.0001 10.1096L13.9092 15.0186"
      stroke="#121212"
      strokeWidth="2"
      strokeLinecap="round"
      className={pathClass}
    />
  </svg>
);

export default Speed1x;
