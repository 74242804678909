import dayjs from 'dayjs';
import { FC, useCallback, useState } from 'react';

import { PlaybackSpeed, PlaybackState, PlaylistEvent, PlaylistEventType, PlaylistSegmentMetadata } from '../../types';
import PlaylistPlayer from '../../lib/audio/PlaylistPlayer';
import { PlaylistSegment } from '../../lib/audio/PlaylistSegment';
import { usePlaylistEvents } from '../../lib/hooks/playlist-events';

import Pause from '../icons/Pause';
import Play from '../icons/Play';
import SkipBackward from '../icons/SkipBackward';
import SkipBackward10 from '../icons/SkipBackward10';
import SkipForward from '../icons/SkipForward';
import SkipForward10 from '../icons/SkipForward10';
import SpeedToggle from './SpeedToggle';
import CoverImage from '../stream/CoverImage';

const LISTEN_TO_EVENTS = [
  PlaylistEventType.PlaylistChanged,
  PlaylistEventType.PositionChanged,
  PlaylistEventType.SegmentChanged,
  PlaylistEventType.SpeedChanged,
  PlaylistEventType.StateChanged,
];

const MiniPlayer: FC = () => {
  const [currentSegmentMetadata, setCurrentSegmentMetadata] = useState<PlaylistSegmentMetadata | undefined>(
    PlaylistPlayer.currentPlaylistSegmentMetadata,
  );
  const [isVisible, setIsVisible] = useState(PlaylistPlayer.hasPlaylist);
  const [nextSegment, setNextSegment] = useState<PlaylistSegment | undefined>(PlaylistPlayer.nextPlaylistSegment);
  const [playbackState, setPlaybackState] = useState<PlaybackState>(PlaylistPlayer.playbackState);
  const [speed, setSpeed] = useState<PlaybackSpeed>(PlaylistPlayer.speed);

  const nextTrack = useCallback(() => {
    PlaylistPlayer.skipToNextSegment();
  }, []);

  const onPlayPauseClick = useCallback(() => {
    if (PlaylistPlayer.isPlaying) {
      PlaylistPlayer.pause();
    } else {
      PlaylistPlayer.play();
    }
  }, []);

  const prevTrack = useCallback(() => {
    PlaylistPlayer.skipToPreviousSegment();
  }, []);

  const skipForward = useCallback((secondsToSkip: number) => {
    PlaylistPlayer.skipForward(secondsToSkip);
  }, []);

  const skipBackward = useCallback((secondsToSkip: number) => {
    PlaylistPlayer.skipBackward(secondsToSkip);
  }, []);

  const playlistEventHandler = useCallback((event: PlaylistEvent) => {
    switch (event.type) {
      case PlaylistEventType.StateChanged:
        setPlaybackState(event.state as PlaybackState);
        break;
      case PlaylistEventType.SpeedChanged:
        setSpeed(event.speed as PlaybackSpeed);
        break;
      case PlaylistEventType.PlaylistChanged:
        setCurrentSegmentMetadata(PlaylistPlayer.currentPlaylistSegmentMetadata);
        setNextSegment(PlaylistPlayer.nextPlaylistSegment);
        setIsVisible(PlaylistPlayer.hasPlaylist);
        break;
      case PlaylistEventType.PositionChanged:
      case PlaylistEventType.SegmentChanged:
        setCurrentSegmentMetadata(PlaylistPlayer.currentPlaylistSegmentMetadata);
        setNextSegment(PlaylistPlayer.nextPlaylistSegment);
        break;
      default:
        console.error(`Ignoring unregistered event type: ${event.type}`);
    }
  }, []);

  usePlaylistEvents(LISTEN_TO_EVENTS, playlistEventHandler);

  const onSpeedChange = useCallback((newSpeed: PlaybackSpeed) => {
    PlaylistPlayer.speed = newSpeed;
  }, []);

  const onScrubChange = useCallback((newPosition: number) => {
    PlaylistPlayer.seekTo(newPosition);
    setCurrentSegmentMetadata(PlaylistPlayer.currentPlaylistSegmentMetadata);
  }, []);

  if (!isVisible) return null;

  return (
    <>
      <div className="m-3 hidden flex-col rounded-xl bg-black-1/3 p-6 backdrop-blur-lg dark:bg-white/10 sm:absolute sm:bottom-0 sm:right-0 sm:flex sm:w-[400px]">
        <div className="flex">
          <button className="mr-3 flex rounded-lg" onClick={onPlayPauseClick}>
            <div className="h-10 w-10 shrink-0">
              <div className="absolute z-20 h-10 w-10">
                {(currentSegmentMetadata?.playlistSegment?.image_url ||
                  currentSegmentMetadata?.playlistSegment?.stream?.image_url) && (
                  <CoverImage
                    mobileSize="sm"
                    desktopSize="sm"
                    providedImageUrl={currentSegmentMetadata?.playlistSegment?.image_url}
                    stream={currentSegmentMetadata?.playlistSegment?.stream}
                  />
                )}
              </div>
              <div className="absolute z-30 flex h-10 w-10 items-center justify-center rounded-lg bg-black-0/50">
                {playbackState === PlaybackState.Playing || playbackState === PlaybackState.Buffering ? (
                  <Pause svgClass="h-5 w-5" pathClass="fill-white stroke-white" />
                ) : (
                  <Play svgClass="h-5 w-5" pathClass="fill-white stroke-white" />
                )}
              </div>
            </div>
          </button>

          <div className="flex grow flex-col content-between gap-1 overflow-hidden text-xs text-black-1 dark:text-gray-3">
            <h3 className="truncate text-left text-sm font-bold leading-compact dark:text-white">
              {currentSegmentMetadata?.playlistSegment?.title}
            </h3>
            <div className="flex gap-1">
              <p>{currentSegmentMetadata?.playlistSegment?.stream?.name}</p>
              <p>&middot;</p>
              <p>{dayjs(currentSegmentMetadata?.playlistSegment?.publishedAt).format('MMM D, h A')}</p>
              {!!currentSegmentMetadata?.timeRemaining && (
                <p className="ml-auto hidden text-right sm:flex">
                  {dayjs.duration(currentSegmentMetadata?.timeRemaining, 'seconds').format('-m:ss')}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="flex w-full py-3">
          <div className="relative flex h-1/2 w-full grow bg-gray-6 dark:bg-gray-3/30">
            <div
              className="absolute flex h-1/2 bg-gradient-to-r from-dragonfruit-1 to-plum-1 dark:bg-gradient-to-r dark:from-plum-1 dark:to-dragonfruit-1"
              style={{ width: `${currentSegmentMetadata?.percentPlayed}%` }}
            />
            <label htmlFor="segmentPosition" className="relative w-full">
              <input
                className="absolute h-1/2 w-full appearance-none bg-transparent accent-plum-1 dark:accent-dragonfruit-1"
                id="segmentPosition"
                max={currentSegmentMetadata?.duration}
                min="0"
                name="segmentPosition"
                onChange={(e) => onScrubChange(parseFloat(e.target.value))}
                step="0.01"
                type="range"
                value={currentSegmentMetadata?.position}
              />
            </label>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <button className="hidden sm:block" onClick={prevTrack}>
            <SkipBackward pathClass="dark:stroke-white" svgClass="w-6 h-6 dark:stroke-white" />
          </button>

          <button onClick={() => skipBackward(10)}>
            <SkipBackward10 pathClass="dark:stroke-white" svgClass="w-6 h-6" />
          </button>

          <SpeedToggle speed={speed} onSpeedChange={onSpeedChange} />

          <button className="hidden sm:block" onClick={() => skipForward(10)}>
            <SkipForward10 pathClass="dark:stroke-white" svgClass="w-6 h-6" />
          </button>

          <button onClick={nextTrack}>
            <SkipForward pathClass="dark:stroke-white" svgClass="w-6 h-6 dark:stroke-white" />
          </button>
          {nextSegment && (
            <h4 className="ml-auto truncate text-left text-sm leading-compact text-black-1 dark:text-gray-3">
              <span className="font-bold ">Next:&nbsp;</span>
              <span>{nextSegment.title}</span>
            </h4>
          )}
        </div>
      </div>

      {/* mobile */}
      <div className="m-2 box-content flex h-16 items-center justify-between rounded-xl bg-black-1/3 backdrop-blur-lg dark:bg-white/10 sm:hidden">
        <button className="flex grow items-center overflow-hidden" onClick={onPlayPauseClick}>
          <div className="h-16 w-16 shrink-0">
            <div className="absolute z-20 h-16 w-16">
              {currentSegmentMetadata?.playlistSegment?.stream.image_url && (
                <CoverImage
                  mobileSize="miniplayer"
                  desktopSize="miniplayer"
                  stream={currentSegmentMetadata?.playlistSegment?.stream}
                />
              )}
            </div>
            <div className="absolute z-30 flex h-16 w-16 items-center justify-center bg-black-0/50">
              {playbackState === PlaybackState.Playing || playbackState === PlaybackState.Buffering ? (
                <Pause svgClass="h-5 w-5" pathClass="fill-white stroke-white" />
              ) : (
                <Play svgClass="h-5 w-5" pathClass="fill-white stroke-white" />
              )}
            </div>
          </div>

          <div className="ml-4 mr-3 flex h-fit shrink grow flex-col justify-center gap-1 overflow-hidden">
            <h3 className="truncate text-left text-sm font-bold leading-compact dark:text-white sm:mt-1">
              {currentSegmentMetadata?.playlistSegment?.title}
            </h3>
            {nextSegment && (
              <h4 className="truncate text-left text-xs leading-compact dark:text-white">
                <span className="font-bold ">Next:&nbsp;</span>
                <span>{nextSegment.title}</span>
              </h4>
            )}
            <div className="flex w-full items-center gap-3 text-sm">
              <div className="flex h-1/2 w-full grow bg-gray-6 dark:bg-gray-3/30">
                <div
                  className="flex h-1/2 bg-plum-1 dark:bg-gradient-to-r dark:from-plum-1 dark:to-dragonfruit-1"
                  style={{ width: `${currentSegmentMetadata?.percentPlayed}%` }}
                />
              </div>
              {!!currentSegmentMetadata?.timeRemaining && (
                <p className="hidden text-right text-xs text-gray-4 dark:text-gray-2 sm:flex">
                  {dayjs.duration(currentSegmentMetadata?.timeRemaining, 'seconds').format('m:ss')}
                </p>
              )}
            </div>
          </div>
        </button>

        <div className="mr-6 flex shrink-0 gap-4">
          <button className="hidden sm:block" onClick={prevTrack}>
            <SkipBackward pathClass="dark:stroke-white" svgClass="w-6 h-6 dark:stroke-white" />
          </button>

          <button onClick={() => skipBackward(10)}>
            <SkipBackward10 pathClass="dark:stroke-white" svgClass="w-6 h-6" />
          </button>

          <SpeedToggle speed={speed} onSpeedChange={onSpeedChange} />

          <button className="hidden sm:block" onClick={() => skipForward(10)}>
            <SkipForward10 pathClass="dark:stroke-white" svgClass="w-6 h-6" />
          </button>

          <button onClick={nextTrack}>
            <SkipForward pathClass="dark:stroke-white" svgClass="w-6 h-6 dark:stroke-white" />
          </button>
        </div>
      </div>
    </>
  );
};

export default MiniPlayer;
