import { PlaybackSpeed } from '../../types';
import Speed1x from '../icons/Speed1x';
import Speed1_2x from '../icons/Speed1_2x';
import Speed1_5x from '../icons/Speed1_5x';
import Speed2x from '../icons/Speed2x';

export type SpeedToggleChangeCallback = (newValue: PlaybackSpeed) => void;

type SpeedToggleParams = {
  speed: PlaybackSpeed;
  supportedSpeeds?: PlaybackSpeed[];
  onSpeedChange: SpeedToggleChangeCallback;
};

const ALL_SPEEDS: Array<PlaybackSpeed> = [
  PlaybackSpeed.One,
  PlaybackSpeed.OnePointTwo,
  PlaybackSpeed.OnePointFive,
  PlaybackSpeed.Two,
];

const iconForSpeed = (speed: PlaybackSpeed) => {
  switch (speed) {
    case PlaybackSpeed.One:
      return <Speed1x pathClass={'dark:stroke-white'} svgClass={'w-6 h-6 dark:stroke-white'} />;
    case PlaybackSpeed.OnePointTwo:
      return <Speed1_2x pathClass={'dark:stroke-white'} svgClass={'w-6 h-6 dark:stroke-white'} />;
    case PlaybackSpeed.OnePointFive:
      return <Speed1_5x pathClass={'dark:stroke-white'} svgClass={'w-6 h-6 dark:stroke-white'} />;
    case PlaybackSpeed.Two:
      return <Speed2x pathClass={'dark:stroke-white'} svgClass={'w-6 h-6 dark:stroke-white'} />;
  }
};

const nextSpeed = (supportedSpeeds: Array<PlaybackSpeed>, currentSpeed: PlaybackSpeed): PlaybackSpeed => {
  const idx = supportedSpeeds.indexOf(currentSpeed);
  const nextIdx = (idx + 1) % supportedSpeeds.length;
  return supportedSpeeds[nextIdx];
};

const SpeedToggle = ({ speed, supportedSpeeds = ALL_SPEEDS, onSpeedChange }: SpeedToggleParams) => {
  return <button onClick={() => onSpeedChange(nextSpeed(supportedSpeeds, speed))}>{iconForSpeed(speed)}</button>;
};

export default SpeedToggle;
