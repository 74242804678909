import { SvgFC } from '../../types';

const Pause: SvgFC = ({ svgClass, pathClass }) => (
  <svg role="img" viewBox="0 0 24 24" fill="none" className={svgClass} xmlns="http://www.w3.org/2000/svg">
    <title>Pause</title>
    <path
      d="M18.75 3.75h-3.375a.75.75 0 0 0-.75.75v15c0 .414.336.75.75.75h3.375a.75.75 0 0 0 .75-.75v-15a.75.75 0 0 0-.75-.75ZM8.625 3.75H5.25a.75.75 0 0 0-.75.75v15c0 .414.336.75.75.75h3.375a.75.75 0 0 0 .75-.75v-15a.75.75 0 0 0-.75-.75Z"
      stroke="#121212"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      className={pathClass}
    />
  </svg>
);

export default Pause;
