const plugin = require('tailwindcss/plugin');

module.exports = {
  content: ['./pages/**/*.{js,ts,jsx,tsx}', './components/**/*.{js,ts,jsx,tsx}'],
  theme: {
    fontFamily: {
      sans: 'Lato, sans-serif',
      serif: 'Bitter, serif',
    },
    colors: {
      dragonfruit: {
        1: '#da01be',
        1.5: '#d2b2ea',
        2: '#ffe7fc',
      },
      plum: {
        1: '#6900bc',
        2: '#ece1f4',
        3: '#D2B2EB',
      },
      black: {
        0: '#000',
        1: '#121212',
        2: '#2c2c2c',
      },
      gray: {
        2: '#595959',
        3: '#8c8c8c',
        4: '#b3b3b3',
        5: '#d1d1d1',
        6: '#dedede',
        7: '#f2f2f2',
        8: '#fafafa',
      },
      strawberry: {
        1: '#de350b',
        2: '#ffe4e4',
      },
      lime: {
        1: '#1c8832',
        2: '#e2f4ae',
      },
      mango: {
        1: '#f07300',
        2: '#fff2c3',
      },
      blueberry: {
        1: '#0061E2',
        2: '#daf2ff',
      },
      white: '#ffffff',
      transparent: 'transparent',
    },
    screens: {
      se: { max: '375px' },
      sm: '600px',
    },
    extend: {
      boxShadow: {
        1: '0px 2px 8px rgba(0, 0, 0, 0.14)',
        2: '0px 8px 16px rgba(0, 0, 0, 0.16)',
        3: '0px 10px 20px rgba(0, 0, 0, 0.2)',
      },
      borderRadius: {
        '2.5xl': '1.25rem',
        '4xl': '2rem',
      },
      borderWidth: {
        0.5: '0.5px',
      },
      fontSize: {
        xxs: '10px',
      },
      height: {
        '1/2': '2px',
        18: '72px',
      },
      width: {
        18: '72px',
      },
      opacity: {
        3: '.03',
      },
      lineHeight: {
        compact: 1.2,
      },
    },
  },
  plugins: [
    require('@tailwindcss/forms'),

    // Ref: https://serversideup.net/using-tailwindcss-to-design-your-mobile-app/
    plugin(({ addUtilities }) => {
      const newUtilities = {
        '.safe-top': {
          paddingTop: 'env(safe-area-inset-top)',
        },
        '.safe-left': {
          paddingLeft: 'env(safe-area-inset-left)',
        },
        '.safe-right': {
          paddingRight: 'env(safe-area-inset-right)',
        },
        '.safe-bottom': {
          paddingBottom: 'env(safe-area-inset-bottom)',
        },
        '.hide-scrollbar::-webkit-scrollbar': {
          display: 'none',
        },
        '.hide-scrollbar': {
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        },
      };

      addUtilities(newUtilities);
    }),
  ],
};
