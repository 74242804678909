export const STREAM_COUNT_PER_DISCOVER_GROUP = 5;
export const STREAM_SEARCH_LIMIT = 25;

export const CATEGORY_STREAMS_PAGE_SIZE = 10;

export const TAG_STREAMS_PAGE_SIZE = 10;
export const SEARCH_QUERY_MIN_LENGTH = 2;

export const STREAM_DAILY_DURATION_MULTIPLIER: Record<string, number> = {
  daily: 1,
  weekly: 0.2,
};

export const DEFAULT_AUTO_CLOSE_DELAY = 5000;

export const LEGACY_IMAGE_CDN = process.env.NEXT_PUBLIC_LEGACY_IMAGE_CDN as string;
export const IOS_APP_STORE_URL = 'https://apps.apple.com/us/app/jam-just-a-minute/id1585766149';
export const GOOGLE_PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=ai.jam.app&pli=1';
